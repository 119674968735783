.Search
{
    padding: 1em;

    h2
    {
        text-align: center;
    }


    .form
    {
        width: fit-content;
        margin: auto;

        div
        {
            display: flex;
            justify-content: space-between;
            align-content: center;
            padding: 0.25em 1em;

            label
            {
                width: 100%;
                margin-right: 1em;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            input
            {
                border: none;
                outline: unset;

                padding: 0.5rem 1rem;

                border-radius: 25px;
                border: 2px solid #d46b1a;

            }

            input[type="button"]
            {
                color: white;
                background-color: #f37a1f;
                cursor: pointer;

                &:hover
                {
                    background-color: #d46b1a;
                }
            }
            

            &:nth-child(3)
            {
                justify-content: center;
            }
        }
    }

    .header
    {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        text-align: center;

        background: #f37a1f;
        color: white;
        padding: 0.5rem;
        font-weight: bold;
    }

    .controls
    {
        display: flex;
        justify-content: center;
        padding: 1em;

        div {
            border: none;
            outline: unset;

            padding: 0.5rem 1rem;
            margin: 0 0.25rem;

            border-radius: 25px;
            border: 2px solid #d46b1a;
            color: white;
            background-color: #f37a1f;
            cursor: pointer;

            &:hover
            {
                background-color: #d46b1a;
            }

            &.active
            {
                filter: brightness(0.8);
            }
        }
    }
}