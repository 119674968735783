.Print
{
    background: white;

    .Page
    {
        width: 21cm;
        min-height: 29.7cm;
        // padding: 1cm;
        margin: 0.5cm auto;

        display: grid;
        grid-template: 10% auto 10% 5% / 1fr 1.5fr 1fr 1fr;

        grid-template-areas:
            "nr zlec name name"
            "main main main main"
            "pod pod pod pod"
            "data_z serwisant podpis data";

            border: 2px solid black;
        
        > div {
            border: 2px solid black;
            padding: 0.5rem;
            text-align: center;
            display: flex;
            justify-content: center;
            flex-direction: column;

            > div
            {
                display: flex;
                flex-direction: column;
            }
        }

        > div:nth-child(1) {
            grid-area: nr;
            align-content: center;
            h2{
                margin: 0;
            }
        }

        > div:nth-child(2) {
            grid-area: zlec;
        }

        > div:nth-child(3) {
            grid-area: name;
            justify-items: center;
            align-items: center;

            img {
                height: 20%;
            }
        }

        > div:nth-child(4) {
            grid-area: main;
            text-align: justify;

            justify-content: flex-start;

            >div
            {

                margin: 0.5rem;
            }
        }

        > div:nth-child(5) {
            grid-area: pod;
            text-align: center;
            p {
                font-size: small;
            }
        }

        > div:nth-child(n+6)
        {
            font-size: smaller;

            justify-content: flex-end;

            b {
                font-size: initial;
            }
        }

        > div:nth-child(6) {
            grid-area: data_z;
        }

        > div:nth-child(7) {
            grid-area: serwisant;
        }

        > div:nth-child(8) {
            grid-area: podpis;
        }

        > div:nth-child(9) {
            grid-area: data;
        }
    }

    .print-div
    {
        display: flex;
        padding: 1em;
        justify-content: center;
    }

    .print-btn
    {
        border: none;
        outline: unset;

        padding: 0.5rem 1rem;

        border-radius: 25px;
        border: 2px solid #d46b1a;

        width: 100%;
        max-width: 6rem;
        text-align: center;

        justify-self: center;

        color: white;
        background-color: #f37a1f;
        cursor: pointer;

        &:hover
        {
            background-color: #d46b1a;
        }
    }

}

@media print {
    .Print {
        .Page {
            margin: 0;
            border: initial;
            border-radius: initial;
            box-shadow: initial;
            background: initial;
            page-break-after: always;
        }
    }

    .print-div
    {
        display: none;

        * {
            display: none;
        }
    }
  }