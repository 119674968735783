.App-header
{
    background-color: #f37a1f;
    padding: 0.5rem 1rem;
    color: white;
    font-style: italic;
    font-weight: bolder;
    font-size: 2rem;
    text-align: right;

    sup
    {
        font-size: 1rem;
    }
}