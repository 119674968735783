.OrderNewItem
{
    width: 100%;

    display: grid;
    grid-template-columns: repeat(4, 1fr);


    &:nth-child(2n - 1)
    {
        background: rgba(0,0,0,0.05);
    }

    &:nth-child(2n)
    {
        background: rgba(0,0,0,0.1);
    }

    div
    {
        padding: 0.25rem;
        display: flex;
        align-items: center;
    }

    &-actions
    {
        display: flex;
        justify-content: center;

        div
        {
            cursor: pointer;
            user-select: none;

            &:hover{
                text-decoration: underline;
            }
        }
    }
}