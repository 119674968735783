.Utils
{
    padding: 1em;

    .header
    {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        text-align: center;

        background: #f37a1f;
        color: white;
        padding: 0.5rem;
        font-weight: bold;
    }
}