.App
{
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

#popup-root
{
    .popup-overlay
    {
        background: rgba(126,126,126,0.5);

        .popup-content
        {
            background: linear-gradient(90deg, #cccacb, #e1e1e1);
            padding: 1rem;

            border-radius: 25px;
            border: 2px solid #d46b1a;
            background-color: #f37a1f;

            display: flex;
            flex-direction: column;


            .title
            {
                text-align: center;
                font-weight: bold;
                font-size: 150%;
                position: relative;

                border-bottom: #d46b1a 2px solid;

            }

            .msg
            {
                text-align: justify;

                position: relative;
                padding: 1rem 0;

                border-bottom: #d46b1a 2px solid;
            }

            .actions
            {
                display: flex;
                padding-top: 1rem;
                justify-content: center;

                .action
                {
                    
                    border: none;
                    outline: unset;
        
                    padding: 0.25rem 0.5rem;
                    margin: 0 0.25rem;
        
                    border-radius: 25px;
                    border: 2px solid #d46b1a;
                    color: white;
                    background-color: #f37a1f;
                    cursor: pointer;
        
                    &:hover
                    {
                        background-color: #d46b1a;
                    }
        
                    &.active
                    {
                        filter: brightness(0.7);
                    }
                    
                }

            }
        }
    }
}