.Order
{
    padding-left: 2em;
    text-align: center;
    overflow-x: auto;

    .form 
    {
        width: fit-content;
        display: flex;
        flex-direction: column;
        margin: auto;

        div {
            display: flex;
            justify-content: space-between;
            margin: 0.25rem 0;

            label {
                display: flex;
                width: max-content;
                justify-content: flex-end;
                align-items: center;
                padding: 0.25rem 1rem;
                flex-grow: 1;
            }

            input {
                min-width: 74%;
            }

            input, button, textarea
            {
                border: none;
                outline: unset;

                padding: 0.5rem 1rem;

                border-radius: 25px;
                border: 2px solid #d46b1a;

            }

            button
            {
                color: white;
                background-color: #f37a1f;
                cursor: pointer;

                &:hover
                {
                    background-color: #d46b1a;
                }
            }

            &.action
            {
                justify-content: center;
                button {
                    margin: 0 1rem;
                }
            }
        }
    }
}