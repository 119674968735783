footer
{
    flex-grow: 0;
    flex-shrink: 0;
}

header
{
    flex-grow: 0;
    flex-shrink: 0;
}

nav
{
    flex-grow: 0;
    flex-shrink: 0;
}


main
{
    flex-grow: 1;
    flex-shrink: 0;
    background: linear-gradient(90deg, #cccacb, #e1e1e1);
}
