.App-navigation
{
    background-color: #d86e1e;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    a
    {
        color: white;
        text-decoration: none;
        background-color: #d86e1e;
        padding: 0.5rem 1rem;
        display: block;

        &:hover
        {
            filter: brightness(0.8);
        }

        &.active
        {
            filter: brightness(0.9);
        }
    }
}