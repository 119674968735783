.Login
{

    form
    {
        margin: auto;
        display: flex;
        flex-direction: column;
        
        min-width: 200px;
        width: 30%;
        text-align: center;
        div
        {
            display: flex;
            justify-content: center;
            margin: 0.25rem 0;

            label
            {
                flex-grow: 0;
                min-width: 4rem;
            }

            input, button
            {
                border: none;
                outline: unset;

                padding: 0.5rem 1rem;

                border-radius: 25px;
                border: 2px solid #d46b1a;

            }

            button
            {
                color: white;
                background-color: #f37a1f;
                cursor: pointer;

                &:hover
                {
                    background-color: #d46b1a;
                }
            }
        }
    }
}